import React from "react";
import SolutionPage from "../../components/SolutionPage";

const data = {
  heroSection: {
    label: {
      title: 'LooprIQ Verify', 
      imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/loopriq-verify-label-logo.png',
    }, 
    heading: [
        {
          text: '100% assembly verification', 
          textColor: 'blue-800'
        },
        {
          text: '& lower recalls', 
          textColor: 'black'
        }
    ], 
    content: "Verify that your high mix and high value products are assembled to customer spec, using customized AI software and off-the-shelf hardware.", 
    // videoSrc: 'https://www.youtube.com/embed/GvNrWErVAf4'
  }, 
  problemsSection: {
    heading: 'Problems with manual assembly verification', 
    content: [
      {
        title: ['Error Prone'], 
        text: 'Missing components or use of incorrect components is common in HMLV assembly', 
        imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/error-prone-icon.png'
      },
      {
        title: ['Inconsistent'], 
        text: 'Eyeballing assembly outcome is subjective to each operator and easy to miss', 
        imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/inconsistent-icon.png'
      },
      {
        title: ['Expensive'], 
        text: 'Product recalls from incorrect assembly incurs high costs and diminishes trust', 
        imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/expensive-icon.png'
      }
    ]   
  }, 
  outcomesSection:{
    content: [
      {
        title: 'Precision', 
        text: 'Ensure assembly of products as per specifications, each time', 
        imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/precision-icon.png', 
        imgDimensions: {
          width: '3.56rem', 
          height: '3.63rem'
        }
      },
      {
        title: 'Lower costs', 
        text: 'Reduce cost of quality with automated assembly checks & reduced product rework', 
        imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/lower-cost-icon.png', 
        imgDimensions: {
          width: '3.72rem', 
          height: '3.63rem'
        }
      },
      {
        title: 'Increased Output', 
        text: 'Increase speed of assembly with consistent and instant verification', 
        imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/increased-output-icon.png', 
        imgDimensions: {
          width: '3.12rem', 
          height: '3.63rem'
        }
      },
    ]
  }, 
  automateSection: {
    heading: ['Automate assembly checks with', 'easy to use AI software'], 
    content: [
      {
        id: '01', 
        title: 'Customized for your parts', 
        text: [
          "LooprIQ Verify’s camera-based software is configured to consistently compare assembled part with a bill-of-material, product specification or benchmark image.",
          'The software can identify individual components of all shapes, sizes, and their location, as a human inspector would.'
        ], 
        imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/loopriq-verify-content-01.png'
      },
      {
        id: '02', 
        title: 'Flexible', 
        text: [
          'Run in a fully-automated mode to verify assembly and communicate with PLC systems or co-pilot mode for scenarios requiring human-in-the-loop.',
          "Works with any camera including endoscopic cameras so you can easily verify assembly of difficult to inspect parts."
        ], 
        imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/loopriq-verify-content-02.png'
      },
      {
        id: '03', 
        title: 'Knowledge store', 
        text: [
          'Get a traceable and permanent database of part inspections, defects, processes and know-how.',
          'Use the database to track, analyze, root cause failures and even train your custom Large Language Models (LLMs).'
        ], 
        imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/loopriq-inspect-content-03.png'
      },
    ]
  }, 
  platformSection: false,
  industries: ['Automotive', 'General Manufacturing', 'Aerospace'],
  bannerImages: [
    'https://looprdevstorage.blob.core.windows.net/website-temp/daimler-truck-logo', 
    'https://looprdevstorage.blob.core.windows.net/website-temp/cascade-logo', 
  ]
}

export default function LooprIQVerify() {
  return (
    <SolutionPage data={data} />
  )
}
